.react-tabs {

    &__tab-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;

        border-bottom: 1px solid theme("colors.gray.200");
        margin-bottom: 1.254rem;

        @media (min-width: 450px) {
            gap: 1.25rem;
        }
        @media (min-width: 1024px) {
            gap: 1.75rem;
        }
    }

    &__tab {
        font-size: 12px;
        cursor: pointer;
        letter-spacing: 0.018em;
        padding: 6px 0;

        &:hover {
            border-bottom: 2px solid theme("colors.gray.200");
        }

        &:focus {
            border-bottom: 2px solid theme("colors.gray.200");
        }

        @media (min-width: 450px) {
            font-size: 14px;
        }

        &--selected {
            letter-spacing: 0em;
            font-weight: 700;
            border-bottom: 2px solid theme('colors.blue.700');
            
            &:hover {
                border-bottom: 2px solid theme('colors.blue.700');
            }
        }

        &--disabled {
            opacity: 0.25;
            pointer-events: none;
        }
    }
}