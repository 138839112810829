.hide-spinner {
    // Chrome, Safari, Edge, Opera

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
}

.container-breakout {
    margin-left: calc(50% - 50vw) !important;
    margin-right: calc(50% - 50vw) !important;
    max-width: 100vw;
}

.custom-shadow {
    box-shadow: 0px 20px 20px -28px #000000;
}
