@font-face {
	font-family: 'Inter';
	src: url('Inter-Regular.eot');
	src: url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
		url('Inter-Regular.woff2') format('woff2'),
		url('Inter-Regular.woff') format('woff'),
		url('Inter-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Inter';
	src: url('Inter-Medium.eot');
	src: url('Inter-Medium.eot?#iefix') format('embedded-opentype'),
		url('Inter-Medium.woff2') format('woff2'),
		url('Inter-Medium.woff') format('woff'),
		url('Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Inter';
	src: url('Inter-Bold.eot');
	src: url('Inter-Bold.eot?#iefix') format('embedded-opentype'),
		url('Inter-Bold.woff2') format('woff2'),
		url('Inter-Bold.woff') format('woff'),
		url('Inter-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: block;
}