@import "../assets/fonts/fonts.css";

* {
	box-sizing: border-box;
}

html,
body {
	padding: 0;
	margin: 0;
	font-size: $textBase;
	font-family: "Inter";
}
html {
	min-height: 100vh;
	height: 100%;
}
body {
	height: 100%;
}

a {
	color: inherit;
	text-decoration: none;
}

// .container {
// 	width: 100%;
// 	max-width: $siteWidth;
// 	padding-left: 20px;
// 	padding-right: 20px;
// 	margin-left: auto;
// 	margin-right: auto;

// 	&.absolute {
// 		left: 50%;
// 		transform: translateX(-50%);
// 	}

// 	& .container {
// 		padding-left: 0;
// 		padding-right: 0;
// 	}
// }
